import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { addToCart, getCartItemCount } from './cartUtils';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';

function CategoryPage() {
  const { categoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [crops, setCrops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedPackingSizes, setSelectedPackingSizes] = useState({});
  const [cartItemCount, setCartItemCount] = useState(0);
  const [quantities, setQuantities] = useState({});
  const [filters, setFilters] = useState({ category: '', brand: '', crop: '' });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/products/category/${categoryId}/products/`
        );
        const productsData = response.data.results || response.data;
        setProducts(productsData);

        const uniqueCategories = [...new Set(productsData.map((p) => p.category.name))];
        const uniqueBrands = [...new Set(productsData.map((p) => p.brand.name))];
        const uniqueCrops = [...new Set(productsData.flatMap((p) => p.recommended_for_names))];
        setCategories(uniqueCategories);
        setBrands(uniqueBrands);
        setCrops(uniqueCrops);

        const defaultPackingSizes = {};
        const defaultQuantities = {};
        productsData.forEach((product) => {
          if (product.packing_sizes.length > 0) {
            defaultPackingSizes[product.id] = product.packing_sizes[0].id;
            defaultQuantities[product.id] = 1;
          }
        });
        setSelectedPackingSizes(defaultPackingSizes);
        setQuantities(defaultQuantities);
      } catch (err) {
        console.error('Error fetching products:', err);
        setError('Failed to load products.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
    setCartItemCount(getCartItemCount());
  }, [categoryId]);

  const handleQuantityChange = (productId, change) => {
    setQuantities((prev) => ({
      ...prev,
      [productId]: Math.max(1, (prev[productId] || 1) + change),
    }));
  };

  const handlePackingSizeChange = (event, productId, packingSizeId) => {
    event.stopPropagation();
    setSelectedPackingSizes((prev) => ({
      ...prev,
      [productId]: packingSizeId,
    }));
  };

  const handleAddToCart = (product) => {
    const selectedPackingSize = product.packing_sizes.find(
      (ps) => ps.id === selectedPackingSizes[product.id]
    );
    addToCart(product, selectedPackingSize, quantities[product.id]);
    setCartItemCount(getCartItemCount());
  };

  const handleProductClick = (productId) => navigate(`/products/${productId}`);

  const handleFilterChange = (filterType, value) => {
    setFilters((prev) => ({ ...prev, [filterType]: value }));
  };

  const filteredProducts = products.filter((product) => (
    (!filters.category || product.category.name === filters.category) &&
    (!filters.brand || product.brand.name === filters.brand) &&
    (!filters.crop || product.recommended_for_names.includes(filters.crop))
  ));

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
        <p className="text-red-500 text-lg font-semibold">{error}</p>
      </div>
    );
  }

  return (
    <section className="bg-gradient-to-b from-gray-50 to-gray-100 min-h-screen py-6 px-4">
      {/* Fixed Header */}
      <div className="fixed top-0 left-0 right-0 bg-white shadow-md z-50 p-4 flex items-center justify-between">
        <h1 className="text-xl font-extrabold text-indigo-600 tracking-tight">
          Category Products
        </h1>
        <Link to="/cart" className="relative">
          <ShoppingCartIcon className="h-6 w-6 text-gray-700" />
          {cartItemCount > 0 && (
            <span className="absolute -top-2 -right-2 bg-indigo-500 text-white text-xs font-bold rounded-full px-2 py-1 animate-pulse">
              {cartItemCount}
            </span>
          )}
        </Link>
      </div>

      {/* Main Content */}
      <div className="pt-16 max-w-7xl mx-auto">
        {/* Filters */}
        <div className="mb-6 bg-white rounded-xl shadow-md p-4">
          <div className="grid grid-cols-1 gap-4">
            {[
              { label: 'Category', key: 'category', options: categories },
              { label: 'Brand', key: 'brand', options: brands },
              { label: 'Crop', key: 'crop', options: crops },
            ].map(({ label, key, options }) => (
              <div key={key}>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Filter by {label}
                </label>
                <select
                  value={filters[key]}
                  onChange={(e) => handleFilterChange(key, e.target.value)}
                  className="w-full p-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-400 focus:outline-none transition-all duration-300"
                >
                  <option value="">All {label}s</option>
                  {options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        </div>

        {/* Product Grid */}
        <div className="grid grid-cols-2 gap-4">
          {filteredProducts.map((product) => (
            <div
              key={product.id}
              className="bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 cursor-pointer flex flex-col"
              onClick={() => handleProductClick(product.id)}
            >
              <div className="w-full aspect-square relative overflow-hidden rounded-t-xl">
                <img
                  src={product.media[0]?.display_image_url || 'https://via.placeholder.com/150'}
                  alt={product.name}
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                  loading="lazy"
                />
              </div>
              <div className="p-3 flex flex-col flex-grow">
                <h2 className="text-sm font-semibold text-gray-900 mb-1 line-clamp-2">
                  {product.name}
                </h2>
                <p className="text-xs text-gray-600 mb-1">Brand: {product.brand.name}</p>
                {product.packing_sizes.length > 0 && (
                  <select
                    value={selectedPackingSizes[product.id] || ''}
                    onChange={(e) =>
                      handlePackingSizeChange(e, product.id, parseInt(e.target.value))
                    }
                    onClick={(e) => e.stopPropagation()}
                    className="w-full p-1 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-400 focus:outline-none mb-2"
                  >
                    {product.packing_sizes.map((ps) => (
                      <option key={ps.id} value={ps.id}>
                        {ps.size} - ₹{ps.discount_price || ps.price}
                      </option>
                    ))}
                  </select>
                )}
                {product.packing_sizes.length > 0 && (
                  <div className="mb-2">
                    <div className="flex items-center space-x-2">
                      <p className="text-xs text-red-500 line-through">
                        ₹{product.packing_sizes.find((ps) => ps.id === selectedPackingSizes[product.id])?.price || 'N/A'}
                      </p>
                      <p className="text-sm text-green-600 font-bold">
                        ₹{product.packing_sizes.find((ps) => ps.id === selectedPackingSizes[product.id])?.discount_price || 'N/A'}
                      </p>
                    </div>
                  </div>
                )}
                <div className="mt-auto flex items-center justify-between">
                  <div className="flex items-center w-24">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleQuantityChange(product.id, -1);
                      }}
                      className="bg-gray-200 px-2 py-1 rounded-l-full hover:bg-gray-300 transition-colors"
                    >
                      -
                    </button>
                    <span className="px-2 py-1 bg-white border-t border-b border-gray-200 text-sm">
                      {quantities[product.id] || 1}
                    </span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleQuantityChange(product.id, 1);
                      }}
                      className="bg-gray-200 px-2 py-1 rounded-r-full hover:bg-gray-300 transition-colors"
                    >
                      +
                    </button>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToCart(product);
                    }}
                    className="bg-indigo-600 text-white px-3 py-1 rounded-full hover:bg-indigo-700 transition-all transform hover:scale-105 text-sm"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default CategoryPage;