import React, { useState, useEffect, useRef } from 'react';
import { getCart } from './cartUtils';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { LockClosedIcon, ShieldCheckIcon, CheckCircleIcon } from '@heroicons/react/24/solid';

function CheckoutPage() {
  const [cartItems, setCartItems] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [newAddress, setNewAddress] = useState({
    name: '',
    street: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    phone_number: '',
  });
  const { apiAuth, isAuthenticated, isLoading } = useAuth();
  const hasFetchedAddresses = useRef(false);
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;

    if (!isAuthenticated) {
      navigate('/login', { state: { from: location.pathname } });
      return;
    }

    setCartItems(getCart());
    fetchAddresses();
  }, [isAuthenticated, isLoading, navigate, location]);

  const fetchAddresses = async () => {
    try {
      const response = await apiAuth.get(`/orders/addresses/`);
      setAddresses(response.data);
      if (response.data.length > 0) {
        setSelectedAddressId(response.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
      alert('Failed to fetch addresses. Please try again.');
    }
  };

  const handleAddressSelection = (id) => {
    setSelectedAddressId(id);
  };

  const handleAddressFormChange = (e) => {
    setNewAddress({ ...newAddress, [e.target.name]: e.target.value });
  };

  const handleAddNewAddress = async (e) => {
    e.preventDefault();
    try {
      const response = await apiAuth.post(`/orders/addresses/`, newAddress);
      setAddresses([...addresses, response.data]);
      setSelectedAddressId(response.data.id);
      setShowAddressForm(false);
      setNewAddress({
        name: '',
        street: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
        phone_number: '',
      });
      alert('Address added successfully.');
    } catch (error) {
      console.error('Error adding new address:', error);
      alert('Failed to add address. Please try again.');
    }
  };

  const calculateSubtotal = () => {
    return cartItems.reduce((total, item) => {
      const price = parseFloat(item.packingSize?.discount_price) || parseFloat(item.packingSize?.price) || 0;
      return total + price * item.quantity;
    }, 0);
  };

  const subtotal = calculateSubtotal();
  const total = subtotal;

  const handlePlaceOrder = async () => {
    if (isPlacingOrder) return; // Prevent multiple clicks
    setIsPlacingOrder(true);

    if (!selectedAddressId) {
      alert('Please select or add a shipping address.');
      setIsPlacingOrder(false);
      return;
    }

    try {
      // Prepare order items
      const orderItems = cartItems.map((item) => ({
        product_id: item.product.id,
        quantity: item.quantity,
        price: item.packingSize?.discount_price || item.packingSize?.price || 0,
        packing_size_id: item.packingSize?.id || null,
      }));

      const orderData = {
        shipping_address_id: selectedAddressId,
        order_items: orderItems,
      };

      // Step 1: Create the order
      const orderResponse = await apiAuth.post(`/orders/orders/`, orderData);
      const order = orderResponse.data;

      // Step 2: Initiate payment with PayU
      const paymentResponse = await apiAuth.post(
        `/orders/initiate_payment/`,
        { order_id: order.id, platform: 'web' },
        { headers: { 'Content-Type': 'application/json' } }
      );

      // Step 3: Inject the PayU form HTML into the DOM
      const paymentFormHtml = paymentResponse.data; // Backend returns HTML string
      const paymentContainer = document.createElement('div');
      paymentContainer.innerHTML = paymentFormHtml;
      document.body.appendChild(paymentContainer);
      paymentContainer.querySelector('form').submit(); // Auto-submit the form

      // Note: The redirection to PayU happens automatically due to the form's onload submit
    } catch (error) {
      console.error('Error placing order or initiating payment:', error);
      const errorMessage = error.response?.data?.error || 'Failed to place order or initiate payment. Please try again.';
      alert(errorMessage);
      setIsPlacingOrder(false);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!isAuthenticated) {
    return (
      <section className="bg-gray-50 min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-6">Welcome to Krshak</h1>
          <p className="mb-6 text-lg">Please log in or register to access our products.</p>
          <div className="space-x-4">
            <Link
              to="/login"
              state={{ from: location }}
              className="bg-blue-500 text-white rounded-md px-6 py-2 hover:bg-blue-600"
            >
              Login
            </Link>
            <Link
              to="/register"
              state={{ from: location }}
              className="bg-green-500 text-white rounded-md px-6 py-2 hover:bg-green-600"
            >
              Register
            </Link>
          </div>
        </div>
      </section>
    );
  }

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white shadow-lg rounded-lg my-10">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Checkout</h1>

      {/* Trust Message */}
      <div className="mb-6 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
        <p className="font-semibold">
          यदि आप खरीद के बाद 30 दिनों में से कम से कम 28 दिनों तक हमारे ऐप से एसएमएस भेजते हैं, तो आप हमारे पास से खरीदे गए बिना बिके उत्पाद को उसी कीमत पर वापस कर सकते हैं।
        </p>
      </div>

      {/* Shipping Address */}
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Shipping Address</h2>
        {addresses.length > 0 ? (
          <div className="space-y-4">
            {addresses.map((address) => (
              <div key={address.id} className="flex items-center">
                <input
                  type="radio"
                  name="address"
                  id={`address-${address.id}`}
                  checked={selectedAddressId === address.id}
                  onChange={() => handleAddressSelection(address.id)}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <label htmlFor={`address-${address.id}`} className="ml-3 text-gray-700">
                  {address.name}, {address.street}, {address.city}, {address.state},{' '}
                  {address.postal_code}, {address.country} - {address.phone_number}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-600">No saved addresses.</p>
        )}
        <button
          onClick={() => setShowAddressForm(!showAddressForm)}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
        >
          {showAddressForm ? 'Cancel' : 'Add New Address'}
        </button>

        {showAddressForm && (
          <form className="mt-6 space-y-4" onSubmit={handleAddNewAddress}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                value={newAddress.name}
                onChange={handleAddressFormChange}
                className="border rounded-md p-2 w-full"
                required
              />
              <input
                type="text"
                name="phone_number"
                placeholder="Phone Number"
                value={newAddress.phone_number}
                onChange={handleAddressFormChange}
                className="border rounded-md p-2 w-full"
                required
              />
            </div>
            <input
              type="text"
              name="street"
              placeholder="Street Address"
              value={newAddress.street}
              onChange={handleAddressFormChange}
              className="border rounded-md p-2 w-full"
              required
            />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <input
                type="text"
                name="city"
                placeholder="City"
                value={newAddress.city}
                onChange={handleAddressFormChange}
                className="border rounded-md p-2 w-full"
                required
              />
              <input
                type="text"
                name="state"
                placeholder="State"
                value={newAddress.state}
                onChange={handleAddressFormChange}
                className="border rounded-md p-2 w-full"
                required
              />
              <input
                type="text"
                name="postal_code"
                placeholder="Postal Code"
                value={newAddress.postal_code}
                onChange={handleAddressFormChange}
                className="border rounded-md p-2 w-full"
                required
              />
            </div>
            <input
              type="text"
              name="country"
              placeholder="Country"
              value={newAddress.country}
              onChange={handleAddressFormChange}
              className="border rounded-md p-2 w-full"
              required
            />
            <button
              type="submit"
              className="bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600 transition-colors"
            >
              Save Address
            </button>
          </form>
        )}
      </div>

      {/* Order Summary */}
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Order Summary</h2>
        <div className="bg-gray-50 p-4 rounded-lg">
          {cartItems.map((item, index) => (
            <div key={index} className="flex justify-between items-center mb-4">
              <div>
                <p className="font-semibold text-gray-800">{item.product.name}</p>
                <p className="text-sm text-gray-600">
                  Quantity: {item.quantity} x ₹{item.packingSize?.discount_price || item.packingSize?.price}
                </p>
              </div>
              <p className="font-semibold text-gray-800">
                ₹{((item.packingSize?.discount_price || item.packingSize?.price) * item.quantity).toFixed(2)}
              </p>
            </div>
          ))}
          <div className="border-t pt-4">
            <div className="flex justify-between text-gray-800 font-bold text-xl mt-2">
              <p>Total</p>
              <p>₹{total.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Trust Icons */}
      <div className="flex items-center space-x-4 mb-6">
        <div className="flex items-center">
          <LockClosedIcon className="w-6 h-6 text-gray-600" />
          <p className="text-gray-600 ml-2">Secure Payment</p>
        </div>
        <div className="flex items-center">
          <ShieldCheckIcon className="w-6 h-6 text-green-600" />
          <p className="text-gray-600 ml-2">Guaranteed Quality</p>
        </div>
        <div className="flex items-center">
          <CheckCircleIcon className="w-6 h-6 text-blue-600" />
          <p className="text-gray-600 ml-2">Certified Vendor</p>
        </div>
      </div>

      {/* Place Order Button */}
      <div className="text-right">
        <button
          onClick={handlePlaceOrder}
          className="w-full md:w-auto bg-blue-500 text-white px-8 py-3 rounded-md hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-400"
          disabled={isPlacingOrder}
        >
          {isPlacingOrder ? 'Processing Payment...' : 'Proceed to Payment'}
        </button>
      </div>
    </div>
  );
}

export default CheckoutPage;