import React from 'react';

const SimpleProductCard = ({ product, onProductClick, className = '' }) => {
  const packingSize = product.packing_sizes[0] || {};
  const originalPrice = parseFloat(packingSize.price || 0);
  const discountPrice = parseFloat(packingSize.discount_price || originalPrice);

  return (
    <div
      className={`bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 cursor-pointer flex flex-col ${className}`}
      onClick={() => onProductClick(product.id)}
    >
      {/* Image */}
      <div className="w-full aspect-square relative overflow-hidden rounded-t-xl">
        <img
          src={
            product.media?.length > 0
              ? product.media[0].display_image_url
              : 'https://via.placeholder.com/150'
          }
          alt={product.name || 'Product Image'}
          className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
          loading="lazy"
        />
      </div>

      {/* Product Info */}
      <div className="p-3 flex flex-col flex-grow">
        <h2 className="text-sm font-semibold text-gray-900 mb-1 line-clamp-2">
          {product.name || 'Product Name'}
        </h2>
        <p className="text-xs text-gray-600 mb-1">Brand: {product.brand?.name || 'N/A'}</p>
        <p className="text-xs text-gray-600 mb-1 line-clamp-1">
          Technical: {product.chemical_names?.length > 0 ? product.chemical_names.join(', ') : 'N/A'}
        </p>
        {packingSize.size && (
          <p className="text-xs text-gray-600 mb-2">Size: {packingSize.size}</p>
        )}

        {/* Pricing Information */}
        <div className="mt-auto">
          {discountPrice > 0 && discountPrice !== originalPrice ? (
            <div className="flex items-center space-x-2">
              <p className="text-xs text-red-500 line-through">₹{originalPrice.toFixed(2)}</p>
              <p className="text-base text-green-600 font-bold">₹{discountPrice.toFixed(2)}</p>
            </div>
          ) : (
            <p className="text-base text-gray-900 font-bold">₹{originalPrice.toFixed(2)}</p>
          )}
          {discountPrice > 0 && discountPrice !== originalPrice && (
            <p className="text-xs text-gray-500 mt-1">
              Save ₹{(originalPrice - discountPrice).toFixed(2)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SimpleProductCard;