import React, { useState, useEffect } from 'react';
import { getCart } from './cartUtils';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function Cart() {
  const [cartItems, setCartItems] = useState([]);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setCartItems(getCart());
  }, []);

  const handleQuantityChange = (product, packingSize, change) => {
    const updatedCart = cartItems.map((item) => {
      if (item.product.id === product.id && item.packingSize?.id === packingSize?.id) {
        const newQuantity = Math.max(1, item.quantity + change);
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const handleRemoveItem = (product, packingSize) => {
    const updatedCart = cartItems.filter(
      (item) => !(item.product.id === product.id && item.packingSize?.id === packingSize?.id)
    );
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const handleProceedToCheckout = () => {
    navigate(isAuthenticated ? '/checkout' : '/login', { state: { from: '/checkout' } });
  };

  const calculateSubtotal = () => {
    return cartItems.reduce((total, item) => {
      const price = item.packingSize?.discount_price || item.packingSize?.price || 0;
      return total + price * item.quantity;
    }, 0).toFixed(2);
  };

  const subtotal = calculateSubtotal();
  const total = subtotal;

  if (cartItems.length === 0) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
        <p className="text-gray-600 text-lg font-semibold">Your cart is empty.</p>
      </div>
    );
  }

  return (
    <section className="bg-gradient-to-b from-gray-50 to-gray-100 min-h-screen py-6 px-4">
      <div className="max-w-5xl mx-auto bg-white rounded-xl shadow-lg p-4 md:p-6">
        <h1 className="text-2xl md:text-3xl font-extrabold text-gray-900 mb-6 text-center tracking-tight">
          Your Cart
        </h1>

        {/* Cart Items */}
        <ul className="divide-y divide-gray-200">
          {cartItems.map((item, index) => (
            <li
              key={index}
              className="py-4 flex flex-col md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-4"
            >
              {/* Thumbnail */}
              <img
                src={item.product.media[0]?.display_image_url || 'https://via.placeholder.com/150'}
                alt={item.product.name}
                className="w-24 h-24 object-cover rounded-lg shadow-sm"
              />

              {/* Product Details */}
              <div className="flex-1">
                <h2 className="text-lg font-semibold text-gray-900 mb-1">{item.product.name}</h2>
                <p className="text-xs text-gray-600">Brand: {item.product.brand?.name || 'N/A'}</p>
                {item.packingSize ? (
                  <>
                    <p className="text-xs text-gray-600 mt-1">
                      Size: <span className="font-medium">{item.packingSize.size}</span>
                    </p>
                    <div className="flex items-center space-x-2 mt-1">
                      <p className="text-xs text-red-500 line-through">₹{item.packingSize.price}</p>
                      <p className="text-sm text-green-600 font-bold">₹{item.packingSize.discount_price}</p>
                    </div>
                  </>
                ) : (
                  <p className="text-xs text-red-600 mt-1">Size unavailable</p>
                )}
                <p className="text-xs text-green-600 mt-1">Free Shipping | 5-7 Days</p>
              </div>

              {/* Quantity & Remove */}
              <div className="flex items-center space-x-3">
                <div className="flex items-center w-24">
                  <button
                    onClick={() => handleQuantityChange(item.product, item.packingSize, -1)}
                    className="bg-gray-200 px-2 py-1 rounded-l-full hover:bg-gray-300 transition-colors"
                  >
                    -
                  </button>
                  <span className="px-2 py-1 bg-white border-t border-b border-gray-200 text-sm">
                    {item.quantity}
                  </span>
                  <button
                    onClick={() => handleQuantityChange(item.product, item.packingSize, 1)}
                    className="bg-gray-200 px-2 py-1 rounded-r-full hover:bg-gray-300 transition-colors"
                  >
                    +
                  </button>
                </div>
                <button
                  onClick={() => handleRemoveItem(item.product, item.packingSize)}
                  className="text-red-500 hover:text-red-600 transition-colors"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </div>

              {/* Price */}
              <div className="text-right">
                <p className="text-lg font-bold text-gray-900">
                  ₹{((item.packingSize?.discount_price || item.packingSize?.price || 0) * item.quantity).toFixed(2)}
                </p>
              </div>
            </li>
          ))}
        </ul>

        {/* Offer Banner */}
        <div className="mt-6 bg-yellow-50 border-l-4 border-yellow-500 p-3 rounded-r-lg text-yellow-700 text-sm">
          यदि आप खरीद के बाद 30 दिनों में से कम से कम 28 दिनों तक हमारे ऐप से एसएमएस भेजते हैं, तो आप हमारे पास से खरीदे गए बिना बिके उत्पाद को उसी कीमत पर वापस कर सकते हैं।
        </div>

        {/* Order Summary */}
        <div className="mt-6 border-t pt-4">
          <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-4">Order Summary</h2>
          <div className="space-y-2 text-sm text-gray-700">
            <div className="flex justify-between">
              <p>Subtotal</p>
              <p>₹{subtotal}</p>
            </div>
            <div className="flex justify-between">
              <p>Tax</p>
              <p>₹0.00</p>
            </div>
            <div className="flex justify-between">
              <p>Shipping</p>
              <p className="text-green-600">Free</p>
            </div>
            <div className="flex justify-between text-lg font-bold text-gray-900 mt-2">
              <p>Total</p>
              <p>₹{total}</p>
            </div>
          </div>

          {/* Checkout Button */}
          <button
            onClick={handleProceedToCheckout}
            className="w-full mt-6 bg-indigo-600 text-white py-2 rounded-full hover:bg-indigo-700 transition-all transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          >
            Proceed to Checkout
          </button>
        </div>
      </div>
    </section>
  );
}

export default Cart;