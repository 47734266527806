import React, { useRef, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { LockClosedIcon, ShieldCheckIcon, CheckCircleIcon, ShoppingCartIcon, ShareIcon } from '@heroicons/react/24/solid';
import { addToCart, getCartItemCount } from './cartUtils';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import SimpleProductCard from '../components/common/SimpleProductCard';
import debounce from 'lodash.debounce';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function ProductDetail() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPacking, setSelectedPacking] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [cartItemCount, setCartItemCount] = useState(0);
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [productRes, bestSellingRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/products/product/${id}`),
          axios.get(`${process.env.REACT_APP_API_URL}/products/bestsellers/`),
        ]);

        const productData = productRes.data;
        setProduct(productData);
        setSelectedPacking(productData.packing_sizes[0] || null);

        let bestProducts = (bestSellingRes.data.results || []).filter(p => p.id !== parseInt(id));
        bestProducts = bestProducts.sort(() => 0.5 - Math.random()).slice(0, Math.min(bestProducts.length, 10));
        setBestSellingProducts(bestProducts);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Unable to load product details.');
      }
      setLoading(false);
      setCartItemCount(getCartItemCount());
    };
    fetchData();
  }, [id]);

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query) {
        setSearchResults([]);
        setSearchLoading(false);
        return;
      }
      setSearchLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/search/`, {
          params: { query },
        });
        setSearchResults(response.data.results || []);
      } catch (error) {
        console.error('Failed to search products:', error);
      }
      setSearchLoading(false);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleAddToCart = () => {
    if (!selectedPacking) return;
    addToCart(product, selectedPacking, quantity);
    setCartItemCount(getCartItemCount());
    toast.success('Added to cart!');
  };

  const handlePurchaseNow = () => {
    if (!selectedPacking) return toast.error('Please select a packing size.');
    addToCart(product, selectedPacking, quantity);
    navigate('/cart');
  };

  const handleShareProduct = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL || window.location.origin;
    const productUrl = `${baseUrl}/products/${product.id}`;
    const message = encodeURIComponent(
      `*${product.name}*\n📂 *Category*: ${product.category?.name || ''}\n🧪 *Technical*: ${product.chemical_names?.join(', ') || ''}\n🌾 *Crops*: ${product.recommended_for_names?.join(', ') || ''}\n📦 *Sizes*: ${product.packing_sizes?.map(ps => ps.size).join(', ') || ''}\n👉 *Details*: ${productUrl}`
    );
    window.open(`https://api.whatsapp.com/send?text=${message}`, '_blank');
    toast.success('Sharing on WhatsApp...');
  };

  const handleProductClick = (productId) => navigate(`/products/${productId}`);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">{error}</div>;
  }

  return (
    <section className="bg-gradient-to-b from-gray-50 to-gray-100 min-h-screen">
      {/* Navigation */}
      <nav className="bg-white shadow-lg fixed top-0 left-0 right-0 z-50">
        <div className="container mx-auto px-4 py-3 flex items-center justify-between">
          <Link to="/ecom" className="text-2xl font-extrabold text-indigo-600 tracking-tight">
            Krshak
          </Link>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search products..."
            className="flex-1 mx-3 px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400 shadow-sm transition-all duration-300"
          />
          <div className="flex items-center space-x-3">
            <Link to="/cart" className="relative">
              <ShoppingCartIcon className="h-6 w-6 text-gray-700" />
              {cartItemCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-indigo-500 text-white text-xs font-bold rounded-full px-2 py-1 animate-pulse">
                  {cartItemCount}
                </span>
              )}
            </Link>
            <ShareIcon
              className="h-6 w-6 text-gray-700 hover:text-indigo-500 cursor-pointer"
              onClick={handleShareProduct}
            />
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="pt-20 px-4 pb-8">
        {/* Search Results */}
        {searchLoading && (
          <div className="text-center py-4">
            <div className="animate-spin inline-block h-6 w-6 border-t-2 border-indigo-500 rounded-full"></div>
          </div>
        )}
        {searchResults.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Results for "{searchQuery}"</h2>
            <div className="grid grid-cols-2 gap-4">
              {searchResults.map((product) => (
                <SimpleProductCard
                  key={product.id}
                  product={product}
                  onProductClick={handleProductClick}
                  className="transform hover:scale-105 transition-transform duration-300"
                />
              ))}
            </div>
          </div>
        )}
        {!searchQuery && (
          <>
            {/* Product Header */}
            <div className="bg-white rounded-xl shadow-lg p-4 mb-6">
              <h1 className="text-2xl font-extrabold text-gray-900 mb-2">{product.name}</h1>
              <div className="flex items-center mb-3">
                <span className="text-yellow-400">★★★★★</span>
                <span className="text-gray-600 text-sm ml-2">(200 reviews)</span>
              </div>
            </div>

            {/* Image Carousel */}
            {product.media.length > 0 && (
              <div className="mb-6">
                <Carousel
                  showThumbs={false}
                  selectedItem={currentImage}
                  onChange={setCurrentImage}
                  showStatus={false}
                  className="rounded-xl overflow-hidden shadow-xl"
                >
                  {product.media.map((image) => (
                    <div key={image.id}>
                      <img
                        src={image.display_image_url}
                        alt={product.name}
                        className="object-contain w-full h-64"
                      />
                    </div>
                  ))}
                </Carousel>
                <div className="flex justify-center mt-3 space-x-2 overflow-x-auto">
                  {product.media.map((image, index) => (
                    <img
                      key={image.id}
                      src={image.display_image_url}
                      alt={`Thumbnail ${index + 1}`}
                      className={`w-12 h-12 object-cover rounded-md cursor-pointer ${index === currentImage ? 'border-2 border-indigo-500' : 'border'}`}
                      onClick={() => setCurrentImage(index)}
                    />
                  ))}
                </div>
              </div>
            )}

            {/* Product Details */}
            <div className="bg-white rounded-xl shadow-lg p-4 mb-6">
              {/* Brand & Category */}
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <h3 className="text-sm font-semibold text-gray-700">Brand</h3>
                  <p className="text-gray-600">{product.brand.name}</p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold text-gray-700">Category</h3>
                  <p className="text-gray-600">{product.category.name}</p>
                </div>
              </div>

              {/* Technical Names */}
              {product.chemical_names.length > 0 && (
                <div className="mb-4">
                  <h3 className="text-sm font-semibold text-gray-700">Technical</h3>
                  <p className="text-gray-600">{product.chemical_names.join(', ')}</p>
                </div>
              )}

              {/* Packing Sizes */}
              {product.packing_sizes.length > 0 && (
                <div className="mb-4">
                  <h3 className="text-sm font-semibold text-gray-700">Packing Size</h3>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {product.packing_sizes.map((packing) => (
                      <button
                        key={packing.id}
                        onClick={() => setSelectedPacking(packing)}
                        className={`px-3 py-1 rounded-full text-sm transition-all duration-200 ${selectedPacking?.id === packing.id ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-indigo-100'}`}
                      >
                        {packing.size}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {/* Pricing */}
              {selectedPacking && (
                <div className="mb-4">
                  <div className="flex items-center space-x-2">
                    <p className="text-red-500 line-through text-sm">₹{selectedPacking.price}</p>
                    <p className="text-green-600 font-bold text-lg">₹{selectedPacking.discount_price}</p>
                  </div>
                  <p className="text-gray-500 text-xs">Save ₹{selectedPacking.price - selectedPacking.discount_price} (Inc. GST)</p>
                </div>
              )}

              {/* Quantity */}
              <div className="mb-4">
                <h3 className="text-sm font-semibold text-gray-700">Quantity</h3>
                <div className="flex items-center mt-2 w-28">
                  <button
                    onClick={() => setQuantity(Math.max(1, quantity - 1))}
                    className="bg-gray-200 px-2 py-1 rounded-l-full hover:bg-gray-300"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    min="1"
                    value={quantity}
                    onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                    className="w-12 text-center border-t border-b border-gray-200 focus:outline-none"
                  />
                  <button
                    onClick={() => setQuantity(quantity + 1)}
                    className="bg-gray-200 px-2 py-1 rounded-r-full hover:bg-gray-300"
                  >
                    +
                  </button>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex space-x-2">
                <button
                  onClick={handleAddToCart}
                  className="flex-1 bg-indigo-600 text-white py-2 rounded-full hover:bg-indigo-700 flex items-center justify-center space-x-2 transition transform hover:scale-105"
                >
                  <ShoppingCartIcon className="h-5 w-5" />
                  <span>Add to Cart</span>
                </button>
                <button
                  onClick={handlePurchaseNow}
                  className="flex-1 bg-green-600 text-white py-2 rounded-full hover:bg-green-700 transition transform hover:scale-105"
                >
                  Buy Now
                </button>
              </div>
            </div>

            {/* Additional Info */}
            <div className="bg-white rounded-xl shadow-lg p-4 mb-6">
              <div className="mb-4">
                <p className="text-gray-700 text-sm font-semibold">Free Shipping | 5-7 Day Delivery</p>
              </div>
              <div className="flex flex-wrap gap-3 text-sm text-gray-600">
                <div className="flex items-center">
                  <LockClosedIcon className="h-5 w-5 text-gray-600 mr-1" />
                  Secure Payment
                </div>
                <div className="flex items-center">
                  <ShieldCheckIcon className="h-5 w-5 text-green-600 mr-1" />
                  Guaranteed Quality
                </div>
                <div className="flex items-center">
                  <CheckCircleIcon className="h-5 w-5 text-indigo-600 mr-1" />
                  Certified Vendor
                </div>
              </div>
            </div>

            {/* Offer */}
            <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4 rounded-r-lg mb-6 text-sm text-yellow-700">
              यदि आप खरीद के बाद 30 दिनों में से कम से कम 28 दिनों तक हमारे ऐप से एसएमएस भेजते हैं, तो आप हमारे पास से खरीदे गए बिना बिके उत्पाद को उसी कीमत पर वापस कर सकते हैं।
            </div>

            {/* Description & Reviews */}
            <div className="bg-white rounded-xl shadow-lg p-4 mb-6">
              <details className="mb-4">
                <summary className="text-sm font-semibold text-gray-700 cursor-pointer">Description</summary>
                <p className="text-gray-600 mt-2 text-sm">{product.description}</p>
              </details>
              <details>
                <summary className="text-sm font-semibold text-gray-700 cursor-pointer">Reviews</summary>
                <p className="text-gray-600 mt-2 text-sm">⭐⭐⭐⭐☆ 4.5/5 - "Great product, highly effective!"</p>
              </details>
            </div>

            {/* Best Selling Products */}
            {bestSellingProducts.length > 0 && (
              <div>
                <h2 className="text-xl font-bold text-gray-900 mb-4">You May Also Like</h2>
                <div className="grid grid-cols-2 gap-4">
                  {bestSellingProducts.map((product) => (
                    <SimpleProductCard
                      key={product.id}
                      product={product}
                      onProductClick={handleProductClick}
                      className="transform hover:scale-105 transition-transform duration-300"
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
}

export default ProductDetail;