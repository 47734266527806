import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Add useNavigate
import { useAuth } from '../context/AuthContext';

function OrderSummaryPage() {
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);
  const { apiAuth, isAuthenticated, isLoading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;

    if (!isAuthenticated) {
      navigate('/login', { state: { from: location.pathname + location.search } });
      return;
    }

    // Get order_id from query params
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('order_id');

    if (!orderId) {
      setError('No order ID provided.');
      return;
    }

    const fetchOrder = async () => {
      try {
        const response = await apiAuth.get(`/orders/orders/${orderId}/`);
        setOrder(response.data);
      } catch (error) {
        console.error('Error fetching order summary:', error);
        setError('Failed to load order summary. Please try again or contact support.');
      }
    };

    fetchOrder();
  }, [apiAuth, isAuthenticated, isLoading, location, navigate]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-xl text-gray-700">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-xl text-red-600">{error}</p>
      </div>
    );
  }

  if (!order) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-xl text-gray-700">Loading order summary...</p>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg my-10">
      <h1 className="text-3xl font-bold mb-6 text-center text-green-600">Order Placed Successfully!</h1>
      <p className="text-lg text-gray-700 mb-4">
        Thank you for your purchase. Your order ID is <span className="font-semibold">#{order.id}</span>.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Order Details</h2>
      <div className="bg-gray-50 p-4 rounded-lg">
        {order.order_items.map((item, index) => (
          <div key={index} className="flex justify-between items-center mb-4">
            <div>
              <p className="font-semibold text-gray-800">{item.product.name}</p>
              <p className="text-sm text-gray-600">
                Quantity: {item.quantity} x ₹{item.price} (GST: {item.gst_rate}%)
              </p>
            </div>
            <p className="font-semibold text-gray-800">
              ₹{(item.price * item.quantity)}
            </p>
          </div>
        ))}
        <div className="border-t pt-4">
          <div className="flex justify-between text-gray-700">
            <p>Subtotal</p>
            <p>₹{order.total}</p>
          </div>
          <div className="flex justify-between text-gray-700">
            <p>Total GST</p>
            <p>₹{order.total_gst}</p>
          </div>
          <div className="flex justify-between text-gray-800 font-bold text-xl mt-2">
            <p>Grand Total</p>
            <p>₹{order.total_amount}</p>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Shipping Address</h2>
        <p className="text-gray-700">
          {order.shipping_address.name}, {order.shipping_address.street}, {order.shipping_address.city},{' '}
          {order.shipping_address.state}, {order.shipping_address.postal_code},{' '}
          {order.shipping_address.country} - {order.shipping_address.phone_number}
        </p>
      </div>
    </div>
  );
}

export default OrderSummaryPage;