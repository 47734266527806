// src/pages/ShopHomepage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useParams } from 'react-router-dom';

const ShopHomepage = () => {
  // Get the logged-in user (if any) and ShopId from URL params.
  const { user } = useAuth();
  const { ShopId } = useParams();

  // Set initial (dummy) Shop data. In practice this comes from your API.
  const [ShopData, setShopData] = useState({
    shopName: 'Your Shop Name',
    logoUrl: '/images/logo.png',
    bannerImageUrl: '/images/banner.jpg',
    heroHeadline: 'Quality Seeds & Trusted Pesticides for Thriving Crops',
    heroSubheadline: 'Your local partner for reliable agricultural inputs.',
    about: 'We have been serving local farmers with the best pesticides and seeds for over 20 years.',
    shopkeeperImage: '/images/shopkeeper.jpg',
    certifications: 'Certified by the Agricultural Authority',
    testimonials: [
      { id: 1, name: 'Farmer Kumar', feedback: 'My crops have never been healthier!', photo: '/images/farmer1.jpg' },
      { id: 2, name: 'Farmer Rani', feedback: 'Excellent service and quality products!', photo: '/images/farmer2.jpg' },
    ],
    promotions: [
      { id: 1, title: 'Summer Sale!', description: 'Get 20% off on all pesticide products.', countdown: '2 days left' },
    ],
    location: {
      address: '123 Rural Road, Agritown, State, ZIP',
      // Example Google Map embed URL – replace with your actual embed code
      mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!...',
    },
    contact: {
      phone: '+1234567890',
      email: 'shop@example.com',
      whatsapp: 'https://wa.me/1234567890',
    },
    socialMedia: {
      facebook: 'https://facebook.com/yourshop',
      instagram: 'https://instagram.com/yourshop',
      twitter: 'https://twitter.com/yourshop',
    },
  });

  // Local state for edit mode.
  const [isEditing, setIsEditing] = useState(false);

  // Fetch Shop data from the backend if a ShopId is provided.
  useEffect(() => {
    if (ShopId) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/Shops/public/${ShopId}/`)
        .then((response) => {
          setShopData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching Shop data:', error);
        });
    }
  }, [ShopId]);

  // Determine if the current user can edit this page.
  // For example, a Shop logged in viewing their own homepage.
  const canEdit =
    user &&
    user.user_type === 'Shop' &&
    (!ShopId || parseInt(ShopId, 10) === user.id);

  // Generic change handler for inputs in edit mode.
  const handleChange = (e) => {
    const { name, value } = e.target;
    setShopData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Example save function – hook this up to your API to persist changes.
  const handleSave = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/Shops/${ShopId}/`, ShopData)
      .then((response) => {
        console.log('Homepage updated successfully');
        setIsEditing(false);
      })
      .catch((error) => {
        console.error('Error updating Shop homepage:', error);
      });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* 1. Header – Logo and Shop Name */}
      <header className="bg-white shadow">
        <div className="container mx-auto px-4 py-4 flex items-center">
          <img
            src={ShopData.logoUrl}
            alt={`${ShopData.shopName} Logo`}
            className="h-12 w-12 mr-4 object-contain"
          />
          {isEditing ? (
            <input
              type="text"
              name="shopName"
              value={ShopData.shopName}
              onChange={handleChange}
              className="text-2xl font-bold border-b border-gray-300 focus:outline-none"
            />
          ) : (
            <h1 className="text-2xl font-bold">{ShopData.shopName}</h1>
          )}
          {canEdit && !isEditing && (
            <button
              onClick={() => setIsEditing(true)}
              className="ml-auto bg-blue-500 text-white px-3 py-1 rounded"
            >
              Edit
            </button>
          )}
        </div>
      </header>

      {/* 2. Hero Section */}
      <section className="relative">
        <img
          src={ShopData.bannerImageUrl}
          alt="Shop Banner"
          className="w-full h-64 object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-center items-center text-center p-4">
          {isEditing ? (
            <>
              <input
                type="text"
                name="heroHeadline"
                value={ShopData.heroHeadline}
                onChange={handleChange}
                className="text-3xl md:text-4xl font-bold text-white bg-transparent border-b border-gray-300 focus:outline-none mb-2"
              />
              <textarea
                name="heroSubheadline"
                value={ShopData.heroSubheadline}
                onChange={handleChange}
                className="text-lg md:text-xl text-white bg-transparent border-b border-gray-300 focus:outline-none"
              />
            </>
          ) : (
            <>
              <h2 className="text-3xl md:text-4xl font-bold text-white">
                {ShopData.heroHeadline}
              </h2>
              <p className="mt-2 text-lg md:text-xl text-white">
                {ShopData.heroSubheadline}
              </p>
              <a
                href={`tel:${ShopData.contact.phone}`}
                className="mt-4 inline-block bg-green-500 hover:bg-green-600 text-white px-6 py-3 rounded"
              >
                Visit Our Shop
              </a>
            </>
          )}
        </div>
      </section>

      {/* 3. About the Shop / Story Section */}
      <section className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/3">
            {isEditing ? (
              <textarea
                name="about"
                value={ShopData.about}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded"
              />
            ) : (
              <p className="text-gray-700">{ShopData.about}</p>
            )}
          </div>
          <div className="md:w-1/3 mt-4 md:mt-0 flex justify-center">
            <img
              src={ShopData.shopkeeperImage}
              alt="Shopkeeper"
              className="h-40 w-40 rounded-full object-cover"
            />
          </div>
          <div className="md:w-1/3 mt-4 md:mt-0 text-center">
            <p className="text-sm text-gray-500">{ShopData.certifications}</p>
            {/* You can add additional trust signals here */}
          </div>
        </div>
      </section>

      {/* 4. Promotions & Offers */}
      <section className="bg-yellow-100 py-8">
        <div className="container mx-auto px-4">
          <h3 className="text-2xl font-bold mb-4 text-center">
            Promotions & Offers
          </h3>
          {ShopData.promotions && ShopData.promotions.length > 0 ? (
            ShopData.promotions.map((promo) => (
              <div
                key={promo.id}
                className="bg-white rounded shadow p-4 mb-4"
              >
                {isEditing ? (
                  <>
                    <input
                      type="text"
                      name={`promotion-title-${promo.id}`}
                      value={promo.title}
                      onChange={(e) => {
                        const newTitle = e.target.value;
                        setShopData((prev) => {
                          const newPromotions = prev.promotions.map((p) =>
                            p.id === promo.id
                              ? { ...p, title: newTitle }
                              : p
                          );
                          return { ...prev, promotions: newPromotions };
                        });
                      }}
                      className="text-xl font-bold border-b border-gray-300 focus:outline-none"
                    />
                    <textarea
                      name={`promotion-description-${promo.id}`}
                      value={promo.description}
                      onChange={(e) => {
                        const newDescription = e.target.value;
                        setShopData((prev) => {
                          const newPromotions = prev.promotions.map((p) =>
                            p.id === promo.id
                              ? { ...p, description: newDescription }
                              : p
                          );
                          return { ...prev, promotions: newPromotions };
                        });
                      }}
                      className="mt-2 w-full border border-gray-300 p-2 rounded"
                    />
                    <p className="mt-2 text-sm text-red-500">
                      {promo.countdown}
                    </p>
                  </>
                ) : (
                  <>
                    <h4 className="text-xl font-bold">{promo.title}</h4>
                    <p className="mt-1">{promo.description}</p>
                    <p className="mt-1 text-sm text-red-500">
                      {promo.countdown}
                    </p>
                  </>
                )}
              </div>
            ))
          ) : (
            <p className="text-center text-gray-600">
              No promotions available at the moment.
            </p>
          )}
        </div>
      </section>

      {/* 5. Testimonials & Success Stories */}
      <section className="py-8">
        <div className="container mx-auto px-4">
          <h3 className="text-2xl font-bold mb-4 text-center">
            Testimonials
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {ShopData.testimonials &&
              ShopData.testimonials.map((testimonial) => (
                <div
                  key={testimonial.id}
                  className="bg-white rounded shadow p-4"
                >
                  <div className="flex items-center mb-2">
                    <img
                      src={testimonial.photo}
                      alt={testimonial.name}
                      className="h-10 w-10 rounded-full mr-2 object-cover"
                    />
                    <p className="font-bold">{testimonial.name}</p>
                  </div>
                  <p className="text-gray-700">{testimonial.feedback}</p>
                </div>
              ))}
          </div>
        </div>
      </section>

      {/* 6. Location & Contact Information */}
      <section className="bg-gray-200 py-8">
        <div className="container mx-auto px-4">
          <h3 className="text-2xl font-bold mb-4 text-center">Find Us</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2">
              <iframe
                src={ShopData.location.mapEmbedUrl}
                title="Shop Location"
                className="w-full h-64 rounded"
                allowFullScreen
              />
            </div>
            <div className="md:w-1/2 mt-4 md:mt-0 md:pl-8">
              <p className="mb-2">
                <strong>Address:</strong> {ShopData.location.address}
              </p>
              <p className="mb-2">
                <strong>Phone:</strong>{' '}
                <a
                  href={`tel:${ShopData.contact.phone}`}
                  className="text-blue-600"
                >
                  {ShopData.contact.phone}
                </a>
              </p>
              
              <p className="mb-2">
                <strong>WhatsApp:</strong>{' '}
                <a
                  href={ShopData.contact.whatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600"
                >
                  Chat with us
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* 7. Social Proof & Community Engagement */}
      

      {/* Save Button (visible only in edit mode) */}
      {isEditing && (
        <div className="fixed bottom-4 right-4">
          <button
            onClick={handleSave}
            className="bg-green-500 text-white px-4 py-2 rounded shadow-lg"
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default ShopHomepage;
