// EcomPage.js

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import { addToCart, getCartItemCount } from './cartUtils';
import { useAuth } from '../context/AuthContext';
import SimpleProductCard from './common/SimpleProductCard';
import ProductCard from './common/ProductCard';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import debounce from 'lodash.debounce';

const SectionTitle = ({ title }) => (
  <h2 className="text-2xl md:text-3xl font-extrabold mb-6 md:mb-8 text-gray-900 text-center tracking-tight">
    {title}
  </h2>
);

const EcomPage = () => {
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [selectedPackingSizes, setSelectedPackingSizes] = useState({});
  const [cartItemCount, setCartItemCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [banners, setBanners] = useState([]);

  const handleAddToCart = (product) => {
    const selectedPackingSize = product.packing_sizes.find(
      (packingSize) => packingSize.id === selectedPackingSizes[product.id]
    );
    const quantity = quantities[product.id] || 1;
    addToCart(product, selectedPackingSize, quantity);
    setCartItemCount(getCartItemCount());
  };

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query) {
        setSearchResults([]);
        setSearchLoading(false);
        return;
      }
      setSearchLoading(true);
      try {
        const token = localStorage.getItem('authToken');
        const config = token ? { headers: { Authorization: `Token ${token}` } } : {};
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/products/search/`,
          { params: { query }, ...config }
        );
        setSearchResults(response.data.results || []);
      } catch (error) {
        console.error('Failed to search products:', error);
      }
      setSearchLoading(false);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleQuantityChange = (productId, change) => {
    setQuantities((prev) => ({
      ...prev,
      [productId]: Math.max(1, (prev[productId] || 1) + change),
    }));
  };

  const handlePackingSizeChange = (e, productId, packingSizeId) => {
    e.stopPropagation();
    setSelectedPackingSizes((prev) => ({
      ...prev,
      [productId]: packingSizeId,
    }));
  };

  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [bestSellingRes, categoryRes, bannerRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/products/bestsellers/`),
          axios.get(`${process.env.REACT_APP_API_URL}/products/categories/`),
          axios.get(`${process.env.REACT_APP_API_URL}/welcome/banners/`),
        ]);

        setBestSellingProducts(bestSellingRes.data.results || []);
        setCategories(categoryRes.data || []);
        
        const baseURL = process.env.REACT_APP_API_URL;
        setBanners(bannerRes.data.map(banner => ({
          ...banner,
          image: `${baseURL}${banner.image}`,
        })));

        const initialPackingSizes = {};
        bestSellingRes.data.results.forEach((product) => {
          if (product.packing_sizes?.length > 0) {
            initialPackingSizes[product.id] = product.packing_sizes[0].id;
          }
        });
        setSelectedPackingSizes(initialPackingSizes);
      } catch (error) {
        console.error('Failed to load data:', error);
      }
      setLoading(false);
    };

    fetchData();
    setCartItemCount(getCartItemCount());
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  return (
    <section className="bg-gradient-to-b from-gray-50 to-gray-100 min-h-screen">
      {/* Navigation Bar */}
      <nav className="bg-white shadow-lg fixed top-0 left-0 right-0 z-50">
        <div className="container mx-auto px-4 py-3 flex items-center justify-between">
          <Link to="/ecom" className="text-2xl font-extrabold text-indigo-600 tracking-tight">
            Krshak
          </Link>
          <div className="flex-1 mx-3">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search products..."
              className="w-full px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400 shadow-sm transition-all duration-300"
            />
          </div>
          <Link to="/cart" className="relative">
            <ShoppingCartIcon className="h-6 w-6 text-gray-700" />
            {cartItemCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-indigo-500 text-white text-xs font-bold rounded-full px-2 py-1 animate-pulse">
                {cartItemCount}
              </span>
            )}
          </Link>
        </div>
      </nav>

      {/* Main Content */}
      <div className="pt-20 pb-8 px-4">
        {/* Hero Carousel */}
        {banners.length > 0 && (
          <Carousel
            showThumbs={false}
            autoPlay
            infiniteLoop
            showStatus={false}
            className="rounded-xl overflow-hidden shadow-xl mb-8"
          >
            {banners.map((banner) => (
              <div key={banner.id} className="relative">
                <img
                  src={banner.image}
                  alt={banner.title || `Banner ${banner.id}`}
                  className="object-cover w-full h-48 md:h-64"
                />
                {banner.link && (
                  <a
                    href={banner.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="absolute inset-0"
                  />
                )}
              </div>
            ))}
          </Carousel>
        )}

        {/* Search Loading */}
        {searchLoading && (
          <div className="text-center py-4">
            <div className="animate-spin inline-block h-6 w-6 border-t-2 border-indigo-500 rounded-full"></div>
          </div>
        )}

        {/* Search Results */}
        {searchResults.length > 0 && (
          <div className="mb-12">
            <SectionTitle title={`Results for "${searchQuery}"`} />
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
              {searchResults.map((product) => (
                <SimpleProductCard
                  key={product.id}
                  product={product}
                  onProductClick={handleProductClick}
                  className="transform hover:scale-105 transition-transform duration-300"
                />
              ))}
            </div>
          </div>
        )}

        {/* Recommended Products */}
        {recommendedProducts.length > 0 && !searchQuery && (
          <div className="mb-12">
            <SectionTitle title="Recommended For You" />
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
              {recommendedProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  quantities={quantities}
                  handleAddToCart={handleAddToCart}
                  handleQuantityChange={handleQuantityChange}
                  handlePackingSizeChange={handlePackingSizeChange}
                  selectedPackingSizes={selectedPackingSizes}
                  onProductClick={handleProductClick}
                  className="transform hover:scale-105 transition-transform duration-300"
                />
              ))}
            </div>
          </div>
        )}

        {/* Best Selling Products */}
        {bestSellingProducts.length > 0 && !searchQuery && (
          <div className="mb-12">
            <SectionTitle title="Best Sellers" />
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
              {bestSellingProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  quantities={quantities}
                  handleAddToCart={handleAddToCart}
                  handleQuantityChange={handleQuantityChange}
                  handlePackingSizeChange={handlePackingSizeChange}
                  selectedPackingSizes={selectedPackingSizes}
                  onProductClick={handleProductClick}
                  className="transform hover:scale-105 transition-transform duration-300"
                />
              ))}
            </div>
          </div>
        )}

        {/* Categories */}
        {categories.length > 0 && !searchQuery && (
          <div>
            <SectionTitle title="Explore Categories" />
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
              {categories.map((category) => (
                <div
                  key={category.id}
                  onClick={() => navigate(`/category/${category.id}/products`)}
                  className="bg-white rounded-xl shadow-md p-4 flex flex-col items-center cursor-pointer hover:shadow-xl hover:-translate-y-1 transition-all duration-300"
                >
                  <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center mb-3">
                    <span className="text-xl font-bold text-indigo-600">
                      {category.name.charAt(0)}
                    </span>
                  </div>
                  <h3 className="text-sm font-semibold text-gray-800 text-center">
                    {category.name}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default EcomPage;