import React from 'react';

const ProductCard = ({
  product,
  quantities = {},
  handleAddToCart = () => {},
  handleQuantityChange = () => {},
  handlePackingSizeChange = () => {},
  selectedPackingSizes = {},
  onProductClick = () => {},
  className = '',
}) => {
  const selectedPackingSize = selectedPackingSizes[product.id] || null;
  const quantity = quantities[product.id] || 1;
  const selectedPacking = product.packing_sizes.find((ps) => ps.id === selectedPackingSize);

  return (
    <div
      className={`bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 cursor-pointer flex flex-col ${className}`}
      onClick={() => onProductClick(product.id)}
    >
      {/* Square Image */}
      <div className="w-full aspect-square relative overflow-hidden rounded-t-xl">
        <img
          src={
            product.media?.length > 0
              ? product.media[0].display_image_url
              : 'https://via.placeholder.com/150'
          }
          alt={product.name}
          className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
          loading="lazy"
        />
      </div>

      {/* Product Info */}
      <div className="p-4 flex flex-col flex-grow">
        <h2 className="text-lg font-bold text-gray-900 mb-1 line-clamp-2">{product.name}</h2>
        <p className="text-xs text-gray-600 mb-1">Brand: {product.brand.name}</p>
        {product.chemical_names?.length > 0 && (
          <p className="text-xs text-gray-600 mb-1 line-clamp-1">
            Technical: {product.chemical_names.join(', ') || 'N/A'}
          </p>
        )}

        {/* Packing Size Selector */}
        {product.packing_sizes.length > 0 && (
          <div className="mb-3">
            <select
              value={selectedPackingSize || ''}
              onChange={(e) => {
                e.stopPropagation();
                handlePackingSizeChange(e, product.id, parseInt(e.target.value));
              }}
              className="w-full p-1 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-400 focus:outline-none"
            >
              {product.packing_sizes.map((packingSize) => (
                <option key={packingSize.id} value={packingSize.id}>
                  {packingSize.size} - ₹{packingSize.discount_price || packingSize.price}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Pricing */}
        {selectedPacking && (
          <div className="mb-3">
            <div className="flex items-center space-x-2">
              <p className="text-red-500 line-through text-xs">₹{selectedPacking.price}</p>
              <p className="text-green-600 font-bold text-base">
                ₹{selectedPacking.discount_price}
              </p>
            </div>
            <p className="text-gray-500 text-xs">
              Save ₹{selectedPacking.price - selectedPacking.discount_price}
            </p>
          </div>
        )}

        {/* Quantity & Add to Cart */}
        <div className="mt-auto flex items-center justify-between space-x-2">
          <div className="flex items-center w-24">
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleQuantityChange(product.id, -1);
              }}
              className="bg-gray-200 px-2 py-1 rounded-l-full hover:bg-gray-300 transition-colors"
            >
              -
            </button>
            <span className="px-2 py-1 bg-white border-t border-b border-gray-200 text-sm">
              {quantity}
            </span>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleQuantityChange(product.id, 1);
              }}
              className="bg-gray-200 px-2 py-1 rounded-r-full hover:bg-gray-300 transition-colors"
            >
              +
            </button>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleAddToCart(product);
            }}
            className="bg-indigo-600 text-white px-3 py-1 rounded-full hover:bg-indigo-700 transition-all transform hover:scale-105 text-sm"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;